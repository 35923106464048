import React, { Component } from 'react';
import logo from './../../assets/images/olivia.png';
import { Link, Redirect } from 'react-router-dom';
import './NavigationCss.css';


class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuLeft: false,
            redirect: false
        }
        this.handleOpenMenu = this.handleOpenMenu.bind(this);
        this.handleCloseMenu = this.handleCloseMenu.bind(this);
        this.logout = this.logout.bind(this);
    }

    handleCloseMenu() {
        this.setState({
            menuLeft: false
        })
    }

    handleOpenMenu() {
        this.setState({
            menuLeft: true
        })
    }

    logout() {
        localStorage.clear();
        this.setState({
            redirect: true
        })
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to={"/login"}></Redirect>
            )
        } else {
            return (
                <header>
                    <nav className="navbar navbar-light bg-light d-flex justify-content-between">
                        <button className="btn btn-outline-grey" onClick={this.handleOpenMenu}>
                            <i className="fas fa-bars"></i> Menú
                    </button>
                        <div className="logo">
                            <Link to="/escritorio">
                                <img src={logo} alt="Olivia" />
                            </Link>
                        </div>
                        <div className="btn-group d-xl-block d-lg-block d-md-block d-sm-none d-none" role="group">
                            <button id="UserSelect" type="button" className="btn btn-outline-grey dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {localStorage.getItem('usuario')}
                            </button>
                            <div className="dropdown-menu collapse" aria-labelledby="UserSelect">
                                <button onClick={this.logout} className="dropdown-item">Cerrar Sesión</button>
                            </div>
                        </div>
                    </nav>
                    {(() => {
                        switch (this.state.menuLeft) {
                            case true:
                                return (
                                    <div className="collapse-menu">
                                        <div className="collapse-menu-content">
                                            <div className="close">
                                                <button onClick={this.handleCloseMenu}>
                                                    <i className="fas fa-times mr-1"></i> <b>Cerrar menú</b>
                                                </button>
                                            </div>
                                            <ul className="content">
                                                <li>
                                                    <Link to="/escritorio" onClick={this.handleCloseMenu}>
                                                        Escritorio
                                                    </Link>
                                                </li>
                                                <li className="header">
                                                    <b>Certificados</b>
                                                </li>
                                                <li>
                                                    <Link to="/certificados" onClick={this.handleCloseMenu}>
                                                        Certificados
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/templates" onClick={this.handleCloseMenu} >
                                                        Templates
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/certificados-aprobacion-verificado" onClick={this.handleCloseMenu}>
                                                        Certificados de aprobación Verificado
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/asistencias" onClick={this.handleCloseMenu}>
                                                        Carga de asistencias
                                                    </Link>
                                                </li>
                                                <li className="header">
                                                    <b>Usuarios</b>
                                                </li>
                                                <li>
                                                    <Link to="/usuarios" onClick={this.handleCloseMenu} >
                                                        Usuarios
                                                    </Link>
                                                </li>
                                                <li className="header d-xl-none d-lg-none d-md-none d-sm-block d-block">
                                                    <b>Cuenta</b>
                                                </li>

                                                <li className="header">
                                                    <b>Open Ai</b>
                                                </li>
                                                <li>
                                                    <Link to="/openai" onClick={this.handleCloseMenu} >
                                                        Open Ai Test
                                                    </Link>
                                                </li>

                                                <li className="header">
                                                    <b>Reportes</b>
                                                </li>
                                                <li>
                                                    <Link to="/reportes" onClick={this.handleCloseMenu} >
                                                        Carga de reportes
                                                    </Link>
                                                </li>

                                                <li className="header">
                                                    <b>Enrollment</b>
                                                </li>
                                                <li>
                                                    <Link to="/productos" onClick={this.handleCloseMenu} >
                                                        Productos
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/reprogramacion" onClick={this.handleCloseMenu} >
                                                        Reprogramaciones
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/calendarios" onClick={this.handleCloseMenu} >
                                                        Calendarios
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/extension" onClick={this.handleCloseMenu} >
                                                        Extension de cursado
                                                    </Link>
                                                </li>

                                                <div className="btn-group d-xl-none d-lg-none d-md-none d-sm-block d-block" role="group">
                                                    <button id="UserSelect" type="button" className="d-block btn btn-outline-grey dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {localStorage.getItem('usuario')}
                                                    </button>
                                                    <div className="dropdown-menu collapse" aria-labelledby="UserSelect">
                                                        <div onClick={this.logout} className="dropdown-item">Cerrar Sesión</div>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            case false:
                                return;
                            default:
                                return;
                        }
                    })()}
                </header>
            )
        }
    }
}
export default Navigation;