import axios from 'axios';
import moment from 'moment-timezone'
const moodle2 = {
    getCoursesByCategory (idCategory,clienteDB) {
        let cliente = this.getURL(clienteDB);
        let url = "?wstoken=" + cliente.token + "&moodlewsrestformat=json&wsfunction=core_course_get_courses_by_field&field=category&value=" + idCategory
        
        return new Promise((resolve, reject) => {
            axios.get(cliente.baseURL+url)
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    createCourse(curso,clienteDB){
        let cliente = this.getURL(clienteDB);
        let url = `?wstoken=${cliente.token}&moodlewsrestformat=json&wsfunction=core_course_create_courses&courses%5B0%5D%5Bfullname%5D=${encodeURI(curso.fullname)}&courses%5B0%5D%5Bcategoryid%5D=${curso.catId}&courses%5B0%5D%5Bshortname%5D=${encodeURI(curso.shortname)}&courses%5B0%5D%5Bstartdate%5D=${encodeURI(curso.stardate)}&courses%5B0%5D%5Benddate%5D=${encodeURI(curso.enddate)}&courses%5B0%5D%5Bidnumber%5D=${encodeURI(curso.sku)}`
        
        return new Promise((resolve, reject) => {
            axios.post(cliente.baseURL+url)
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getUsersByCourse(courseId,clienteDB) {
        let cliente = this.getURL(clienteDB);
        let url = "?wstoken="+ cliente.token +"&moodlewsrestformat=json&wsfunction=core_enrol_get_enrolled_users&courseid=" + courseId + "&options[0][name]=userfields&options[0][value]=['roles','email']";
        return new Promise((resolve, reject) => {
            axios.get(cliente.baseURL+url)
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    async enrollUserToCourse(curso, usuario, clienteDB, inicio, fin) {
        
        try{
            let cliente = this.getURL(clienteDB);
            // let offsetStart = moment(new Date(inicio*1000)).tz(cliente.timezone).utcOffset();
            let offsetEnd = moment(new Date(fin*1000)).tz(cliente.timezone).utcOffset();
            let offsetLocal = moment.tz(moment.tz.guess()).utcOffset();
            // const offsetDifferenceStart = offsetStart - offsetLocal;
            const offsetDifferenceEnd = offsetEnd - offsetLocal;
            // let enrolStart = inicio-(offsetDifferenceStart*60);
            let enrolEnd = fin-(offsetDifferenceEnd*60);
            let url = "?wstoken=" + cliente.token + "&moodlewsrestformat=json&wsfunction=enrol_manual_enrol_users&enrolments[0][roleid]=5&enrolments[0][userid]=" + usuario + "&enrolments[0][courseid]=" + curso + "&enrolments[0][timestart]=" + inicio + "&enrolments[0][timeend]=" + enrolEnd;
            let response = await new Promise((resolve, reject) => {
                axios.post(cliente.baseURL + url)
                    .then(response => {
                        resolve(response)
                    }).catch((error) => {
                        reject(error);
                    })
            })
            return response;
        }catch(e){
            console.log(e);
        }
    },
    getURL (clienteDB) {
        let cliente = {
            baseURL: "https://" + clienteDB.dominio + "/" + clienteDB.path_moodle2 + "/webservice/rest/server.php",
            token: clienteDB.moodleToken2,
            timezone: clienteDB.moodle2TZ
        }
        return cliente;
    }
}
export default moodle2;