import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation/Navigation';
import Loader from '../components/Global/Loader';
import LoaderSecondary from '../components/Global/LoaderSecondary';
import { Animated } from "react-animated-css";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
const columns = [
    {
        dataField: 'id',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort" key={column.id}></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.id}></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.id}></i>);
            return null;
        },
        text: 'Id',
        align: 'center',
        headerClasses: 'border-0',
        uniqueData: "id",
        headerStyle: () => {
            return { width: "5%" };
        },
        formatter: (cellContent, row) => {
            return (
                <span className="badge badge-info">{row.id}</span>
            );
        }
    },
    {
        dataField: 'idMagento',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Id Magento',
        align: 'center',
        headerClasses: 'border-0',
        uniqueData: "idMagento",
        headerStyle: () => {
            return { width: "8%" };
        },
        formatter: (cellContent, row) => {
            return (
                <span className="badge badge-secondary">{row.idMagento}</span>
            );
        }
    },
    {
        dataField: 'idHubspot',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Id Hubspot',
        align: 'center',
        headerClasses: 'border-0',
        uniqueData: "idHubspot",
        headerStyle: () => {
            return { width: "8%" };
        },
        formatter: (cellContent, row) => {
            return (
                <span className="badge badge-secondary">{row.idHubspot}</span>
            );
        }
    },
    {
        dataField: 'nombre',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Nombre',
        headerClasses: 'border-0',
        headerStyle: () => {
            return { width: "40%" };
        },
        uniqueData: "nombre",
        style: {
            width: "200px"
        }
    },
    {
        dataField: 'sku',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Sku',
        headerClasses: 'border-0',
        uniqueData: "sku"
    },
    {
        dataField: 'tipo',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Tipo',
        headerClasses: 'border-0',
        uniqueData: "tipo"
    },
    {
        dataField: 'acciones',
        text: 'Acciones',
        headerClasses: 'border-0',
        uniqueData: "acciones",
        formatter: (row) => {
            return (
                <Link to={`/extension/${row}`} className="btn btn-secondary mr-3">Editar</Link>
            );
        }
    }
];
const rowClasses = 'custom-row-class';
const MySearch = (props) => {
    let input;
    const handleClick = () => {
        props.onSearch(input.value);
    };
    return (
        <input
            id="searchCustom"
            className="form-control input-search custom-big-search"
            ref={n => input = n}
            type="text"
            placeholder="Buscar"
            onChange={handleClick}
        />
    );
};
class routeListReprogramacion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading:{
                cliente:    false,
                productos: false,
            },
            clientes:       null,
            clienteSelected: null,
            productos: null
        }
        this.obtenerClientes    = this.obtenerClientes.bind(this);
        this.searchBar          = this.searchBar.bind(this);
        this.selecCliente       = this.selecCliente.bind(this);
        this.handleSeleccion    = this.handleSeleccion.bind(this);
        this.buscarProductos = this.buscarProductos.bind(this);
    }
    componentDidMount() {
        this.obtenerClientes();
    }
    obtenerClientes() {
        this.setState({
            loading:{
                ...this.state.loading,
                cliente: true,
            }
        })
        if (localStorage.getItem('clientes') !== null) {
            this.setState({
                clientes: JSON.parse(localStorage.getItem('clientes')),
                loading:{
                    ...this.state.loading,
                    cliente: false,
                }
            })
        } else {
            let myHeaders = new Headers();
            myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(window.$UrlOctavio + "/clientes", requestOptions)
                .then(response => response.json())
                .then(result => {
                    let clientes = [];
                    for (let i = 0; i < result.length; i++) {
                        clientes.push({
                            'id': result[i].id,
                            'dominio': result[i].dominio,
                            'path_moodle': result[i].path_moodle,
                            'path_moodle2': result[i].path_moodle2,
                            'moodleToken': result[i].moodleToken,
                            'moodleToken2': result[i].moodleToken2,
                            'moodle2TZ': result[i].moodle2TZ
                        })
                    }
                    this.setState({
                        clientes: clientes,
                        loading:{
                            ...this.state.loading,
                            cliente: false,
                        }
                    })
                })
                .catch(error => console.log('error', error));
        }
    }
    route(){
        return (
            <div className="bg-light pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <label className="navigation-breadcrumb">Inicio &gt; Extension</label>
                                <h5>Extension de cursado</h5>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
    searchBar(){
        return (
            <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-start align-items-center">
                                {this.selecCliente()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    selecCliente() {
        if (this.state.clientes) {
            if (this.state.clientes.length === null || this.state.clientes.length === undefined) {
                return <LoaderSecondary></LoaderSecondary>
            } else {
                let clientesNuevoEnrolment = this.state.clientes.filter(cliente => {
                    return cliente.path_moodle2 !== null && cliente.path_moodle2 !== ''; 
                })
                return <Fragment>
                    <label className="m-0 mr-3" htmlFor="id_cliente">Cliente</label>
                    <select type="text" className="form-control mb-2" name="id_cliente" onChange={this.handleSeleccion} required>
                        <option value="">Selecciona el cliente</option>
                        {clientesNuevoEnrolment.map(cliente => {
                            return <option key={`cliente${cliente.id}`} value={JSON.stringify(cliente)}>{`${cliente.id} - ${cliente.dominio}`}</option>
                        })}
                    </select>
                </Fragment>
            }
        } else{
            return <LoaderSecondary></LoaderSecondary>
        }
    }
    async handleSeleccion(e) {
        const { value, name } = e.target;
        switch(name){
            case 'id_cliente':
                this.setState({
                    ...this.state,
                    clienteSelected: await JSON.parse(value),
                })
                sessionStorage.setItem('clienteSelected', value);
                break;
            default:
                this.setState({
                    ...this.state,
                    [name]: value,
                })
                break;
        }
        this.buscarProductos();
    }
    async buscarProductos(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        let productosStorage = JSON.parse(sessionStorage.getItem('productosMoodle2')) ? JSON.parse(sessionStorage.getItem('productosMoodle2')) : [];
        let existe = productosStorage ? productosStorage.filter(prod => prod.id === this.state.clienteSelected.id) : [];
        if(existe.length !== 0 && productosStorage.length > 0){
            
            this.setState({
                ...this.state,
                productos: existe[0].productos
            })
        }else {
            this.setState({
                ...this.state,
                loading:{
                    ...this.state.loading,
                    productos: true,
                }
            })
            let page = 1;
            let result = [];
            let data = [];
            let productosMoodle=[]; 
            try{
                result = await this.getProductos(page);
                if(!result.error){
                    data = result;
                    while(result.length !== 0){
                        page++;
                        result = await this.getProductos(page);
                        result.forEach(producto =>{
                            data.push(producto);
                        })
                    }
                    productosMoodle = await data.filter(prod => prod.lms === 3);
                    this.setState({
                        ...this.state,
                        productos: productosMoodle,
                        loading:{
                            ...this.state.loading,
                            productos: false
                        }
                    })
                } else{
                    throw new Error(result.error)
                }
            } catch(err){
                console.log(err);
            } 
            let cliente = {
                id: this.state.clienteSelected.id,
                productos: productosMoodle
            }
            productosStorage.push(cliente);
            sessionStorage.setItem('productosMoodle2', JSON.stringify(productosStorage));
        }
    }
    getProductos(page=1){
        try {
            return new Promise((res,rej)=>{
                let productos = [];
    
                var myHeaders = new Headers();
                myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
                myHeaders.append("cliente", this.state.clienteSelected.id);
    
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
    
                fetch(window.$UrlOctavio + "/productos/?page=" + page, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(!!result.error) {
                        this.setState({
                            ...this.state,
                            loading:{
                                ...this.state.loading,
                                productos: true,
                            },
                            productos: false
                        })
                        rej(result);
                    } else {
                        result.forEach(producto =>{
                            // if(producto.activado === 1){
                                productos.push({
                                    'id': producto.id,
                                    'idMagento': producto.idMagento,
                                    'idHubspot': producto.idHubspot,
                                    'nombre': producto.nombre,
                                    'sku': producto.sku,
                                    'tipo': producto.tipo,
                                    'acciones': producto.id,
                                    'lms': producto.idsMoodle2 !== null && producto.idsMoodle2 !== '' && producto.idsMoodle2 !== undefined && producto.idsMoodle2 !== '{}' ? 3 : 1
                                })
                            // }
                        })
                        console.log(productos)
                        res(productos);
                    }
                })
                .catch(error => {
                    rej(error);
                    console.log('error', error)
                })
            })
            
        } catch (error) {
            console.log(error)
        }
        
    }
    renderProductos() {
        if (this.state.loading.productos === true) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Loader></Loader>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (this.state.productos === null) {
                return (
                    false
                )
            } else {
                const defaultSortedBy = [{
                    dataField: "id",
                    order: "desc"
                }];
                return (
                    <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-12">
                                    <ToolkitProvider
                                        keyField='id'
                                        data={this.state.productos}
                                        columns={columns}
                                        search={<MySearch />}
                                        exportCSV
                                    >
                                        {
                                            props => (
                                                <Fragment>
                                                    <div className="search-group d-flex align-items-center justify-content-start mb-5" key={4898794654654}>
                                                        <label htmlFor="searchCustom" className="customlabelSearch">Buscar productos</label>
                                                        <MySearch
                                                            {...props.searchProps}
                                                        />
                                                    </div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        bordered={false}
                                                        headerClasses="header-class"
                                                        pagination={paginationFactory()}
                                                        {...props.baseProps}
                                                        rowClasses={rowClasses}
                                                        defaultSorted={defaultSortedBy}
                                                    />
                                                </Fragment>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </div>
                    </Animated>
                )
            }
        }
    }
    render() {
        return (
            <Fragment>
                <Navigation />
                {console.log(this.state)}
                {this.route()}
                {this.searchBar()}
                {this.renderProductos()}
            </Fragment>
        )
    }
}
export default routeListReprogramacion;